var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "white--text",
                    attrs: {
                      color: "primary",
                      disabled: _vm.selected.length != 1,
                      text: ""
                    }
                  },
                  on
                ),
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-table-edit")
                  ]),
                  _vm._v(" edit description ")
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.editDialog,
        callback: function($$v) {
          _vm.editDialog = $$v
        },
        expression: "editDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center title primary--text" },
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v("mdi-table-edit")
                ]),
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.objectType) + " edit description")
                ])
              ],
              1
            )
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "mt-1" },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Old " + _vm._s(_vm.objectType) + " description:")
              ]),
              _c("p", [_vm._v(_vm._s(_vm.oldName))]),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("New " + _vm._s(_vm.objectType) + " description:")
              ]),
              _c("v-text-field", {
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _vm._v(
                          "Provide a new " +
                            _vm._s(_vm.objectType) +
                            " description"
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.newObjectName,
                  callback: function($$v) {
                    _vm.newObjectName = $$v
                  },
                  expression: "newObjectName"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: { disabled: _vm.editing, text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.editDialog = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { loading: _vm.editing, text: "", color: "error" },
                  on: { click: _vm.edit }
                },
                [_vm._v("confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }